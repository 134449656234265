/* Inject dynatrace js based on dynatraceBrand in the url */

const queryString = window.location.search;
console.log(queryString);
const urlParams = new URLSearchParams(queryString);
let dynatraceBrand = '';
if (urlParams.has('brand'))
{
    dynatraceBrand = urlParams.get('brand');
    console.log('Dynatrace Brand: ', dynatraceBrand);
    var script = document.createElement('script'); 
    script.type = 'text/javascript'; 
    script.crossOrigin = 'anonymous'; 
    if (dynatraceBrand == 'cbu' || dynatraceBrand == 'r4b') { 
        script.src = 'https://js-cdn.dynatrace.com/jstag/17a2d2e42ea/bf97268ops/ce606e7a9dfabb4e_complete.js'; 
        $('head').append(script); 
    }
    else if (dynatraceBrand == 'fido') { 
        script.src = 'https://js-cdn.dynatrace.com/jstag/17a2d2e42ea/bf97268ops/6c576ea150c72184_complete.js'; 
        $('head').append(script); 
    } 
    else if (dynatraceBrand == '1source') { 
        script.src = 'https://js-cdn.dynatrace.com/jstag/17a2d2e42ea/bf97268ops/6e8a13e7bd81b2aa_complete.js'; 
        $('head').append(script); 
    } 
    else if (dynatraceBrand == 'chatr') { 
        script.src = 'https://js-cdn.dynatrace.com/jstag/17a2d2e42ea/bf97268ops/24e7659accc03_complete.js'; 
        $('head').append(script); 
    } 
    else { 
        console.warn('Dynatrace unknown Brand found in URL. ', dynatraceBrand);        
    }    

}
else {   
    console.warn('Dynatrace Brand not found');
}   
    